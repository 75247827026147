.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.app-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.layout header {
    height: var(--header-height);
    background-color: #fff;
}
.layout .body {
    height: calc(100vh - var(--header-height) - var(--footer-height));
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--bg-secondary);
}
.layout footer {
    height: var(--footer-height);
}
.layout footer .bottom-nav {
    /* background-color: var(--bg-secondary);  */
}
.bottom-nav.add-icon {
    background-color: red !important;
    color: #fff !important;
    margin-top: -25px;
    border: 6px solid;
    border-bottom-color: #e1e8ee;
    border-right-color: #e1e8ee;
    /* border-top-color: transparent;  
  border-left-color: transparent;  */
    transform: rotate(45deg);
}
.card .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 4px;
}
.btn-tag {
    background-color: #ff8800 !important;
    font-weight: bold !important;
    border-radius: 2px;
    padding: 5px 10px !important;
    line-height: 1 !important;
    height: fit-content !important;
    box-shadow: none;
}
.logo-placeholder {
    width: 100%;
    max-height: 200px;
    cursor: pointer;
    object-fit: contain;
}
.business-logo-input {
    display: none;
}
.sign-placeholder {
    height: 200px;
    background-color: #f1f1f1;
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
}
#invoice-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 1000;
    height: 100%;
    width: 100%;
    overflow: auto;
}
.profile-pic-img {
    background: var(--smoke);
    object-fit: cover;
}
.profile-pic {
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.profile-pic span {
    color: white !important;
    text-transform: capitalize;
    font-family: medium;
}

.profile-popup {
    list-style: none;
}
.profile-popup li {
    display: flex;
    padding: 10px;
    min-width: 160px;
    cursor: pointer;
}
.profile-popup li:hover {
    background-color: var(--bg-secondary);
}

/* loading component */
.loading_wrapper {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.loading_circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #000;
    left: 15%;
    transform-origin: 50%;
    animation: loading_circle 0.5s alternate infinite ease;
}

@keyframes loading_circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0%;
    }
}
.loading_circle:nth-child(2) {
    left: 45%;
    animation-delay: 0.2s;
}
.loading_circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
}
.loading_shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: loading_shadow 0.5s alternate infinite ease;
}

@keyframes loading_shadow {
    0% {
        transform: scaleX(1.5);
    }
    40% {
        transform: scaleX(1);
        opacity: 0.7;
    }
    100% {
        transform: scaleX(0.2);
        opacity: 0.4;
    }
}
.loading_shadow:nth-child(4) {
    left: 45%;
    animation-delay: 0.2s;
}
.loading_shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
}
.loading_wrapper span {
    position: absolute;
    top: 75px;
    font-family: "Poppins";
    font-size: 20px;
    letter-spacing: 12px;
    color: var(--text-color);
    font-weight: bold;
    left: 15%;
}
.loding_hoc_container {
    z-index: 10001;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}
#loading-hoc .loading_hoc_bg {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

/* -------- loading component ends -------- */

.circular-icon {
    border-radius: 50px;
    padding: 6px;
}

.invoice-header .business-logo{
    /* max-width: 60%; */
}
.business-address {
    word-break: break-all;
}
.table-body .table-row{
    border-bottom: 1px solid lightgrey;
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.input_text_cont{
    width: 100%;
}
.input_text_cont .input_text{
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;
}
.input_text_cont .input_text:focus,
.input_text_cont .input_text:active
{
    border-bottom: 1px solid lightgrey;
}
